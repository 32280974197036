import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Front Squat 5×2\\@85% 1RM`}</p>
    <p>{`Glute Ham Raise 5×4`}</p>
    <p>{`then,`}</p>
    <p>{`10:00 Ascending AMRAP:\\
1,2,3,4,5 etc Power Cleans (155/105)(RX+ 185/125)`}</p>
    <p>{`2,4,6,8,10 etc Wall Balls (20/14)(RX+ 30/20)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      